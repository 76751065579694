import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVER_URL } from '../configuration';

const buttonStyles = {
    color: '#003087',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '24px',
    cursor: 'pointer',
    background: '#ffcc29',
};

function VerifyEmail() {
  const { token } = useParams();
  const [verificationResult, setVerificationResult] = useState(null);
  const navigate = useNavigate()

  function navigateHome(event) {
    navigate("/")
    event.preventDefault();
  }

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    };
    
    fetch(`${SERVER_URL}/verify-email`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setVerificationResult({ success: true, message: data.detail });
      })
      .catch((error) => {
        setVerificationResult({ success: false, message: error.detail || 'An error occurred during verification.' });
      });
  }, [token]);

  return (
    <div>
      {verificationResult ? (
        verificationResult.success ? (
            <>
                <p className="success-message">{verificationResult.message}</p>
                <button style={buttonStyles} onClick={navigateHome}>Back to lobby</button>
            </>
        ) : (
          <p className="error-message">{verificationResult.message}</p>
        )
      ) : (
        <p>Verifying email...</p>
      )}
    </div>
  );
};

export default VerifyEmail;
