import React from "react";
import { useGlobalStore } from "../../App";
import { useCookies } from 'react-cookie';
import {MAINSOCKET_CONFIG, MAINSOCKET_URL, SERVER_URL} from "../../configuration";
import { Link, useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket';

const dropdownStack = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
}

const dropDownItem = {
    background: 'none',
    width: '100%',
	color: 'white',
    margin: '0px',
	border: '0px',
	font: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
    textAlign: 'left',
    padding: '15px 0 15px 0',
    fontFamily: 'courier-bold',
}

function LogoutDropdown() {
    const resetStore = useGlobalStore((state) => state.resetStore)
    const navigate = useNavigate()
    const [cookies,,] = useCookies(['csfrtoken']);
    const mainSocket = useWebSocket(
      MAINSOCKET_URL,
      MAINSOCKET_CONFIG
    )
    
    
    function handleLogout(event) {
        fetch(
            `${SERVER_URL}/logout`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type':'application/json',
                    'X-CSRFToken': cookies.csrftoken,
                },
            }
        )
        .then(response => {
            resetStore()
            mainSocket.sendJsonMessage({
                "request": "disconnect",
            })
            return response
        })
        event.preventDefault();
    }

    function handleTradeCoins(event) {
        navigate("/trade");
    }

    function handleLeaveFeedback(event) {
        navigate("/feedback")
    }

  return (
            <div className="loginDropdownContent">
                <ul style={dropdownStack}>
                    <li><Link style={dropDownItem} to='/rules'>Rules</Link></li>
                    <li><Link style={dropDownItem} to='/about'>About</Link></li>
                    {/* <li><button style={dropDownItem} onClick={(e) => handleTradeCoins(e)}>Trade Coins</button></li> */}
                    <li><button style={dropDownItem} onClick={(e) => handleLeaveFeedback(e)}>Leave Feedback</button></li>
                    <li><button style={dropDownItem} onClick={handleLogout}>Logout</button></li>
                </ul>
            </div>
    )
}

export default LogoutDropdown