import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import Navbar from './components/Navbar/Navbar';
import { MAINSOCKET_URL, MAINSOCKET_CONFIG } from "./configuration";
import { useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import styled from 'styled-components';
import VerifyEmail from './pages/VerifyEmail';

const MainWrap = styled.div `
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  `

const User = lazy(() => import('./pages/User'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Playground = lazy(() => import('./pages/Playground'));
const About = lazy(() => import('./pages/About'));
const Rules = lazy(() => import('./pages/Rules'));
const Home = lazy(() => import('./pages/Home'));
const Feedback = lazy(() => import('./pages/Feedback'));
const Trade = lazy(() => import('./pages/Trade'));

const initialState = {username: "Guest", email: "", emailVerificationStatus: false, realMoney: false, rating: 0, dollars: 0, gameId: 0, gameState: [], listData: [], mobile: false}

export const useGlobalStore = create(
  persist(
    (set) => ({
      ...initialState,
      setUsername: (u) => {
        return set((state) => ({...state, username: u }))
      },
      setEmail: (e) => {
        return set((state) => ({...state, email: e }))
      },
      setEmailVerificationStatus: (v) => {
        return set((state) => ({...state, emailVerificationStatus: v }))
      },
      setRating: (c) => {
        return set((state) => ({...state, rating: c }))
      },
      setDollars: (d) => {
        return set((state) => ({...state, dollars: d }))
      },
      setGameId: (g) => {
        return set((state) => ({...state, gameId: g }))
      },
      setGameState: (g) => {
        return set((state) => ({...state, gameState: g }))
      },
      setListData: (l) => {
        return set((state) => ({...state, listData: l }))
      },
      setMobile: (m) => {
        return set((state) => ({...state, mobile: m }))
      },
      setRealMoney: (r) => {
        return set((state) => ({...state, realMoney: r }))
      },
      resetStore: () => {
        return set(() => ({...initialState}))
      },
    }),
    {name: 'big2-storage'}
  )
)

function App() {
  const username = useGlobalStore((state) => state.username)
  const setRating = useGlobalStore((state) => state.setRating)
  const setDollars = useGlobalStore((state) => state.setDollars)
  const setGameId = useGlobalStore((state) => state.setGameId)
  const listData = useGlobalStore((state) => state.listData)
  const setListData = useGlobalStore((state) => state.setListData)
  const setGameState = useGlobalStore((state) => state.setGameState)
  const gameState = useGlobalStore((state) => state.gameState)
  const setMobile = useGlobalStore((state) => state.setMobile)
  const realMoney = useGlobalStore((state) => state.realMoney)
  const navigate = useNavigate()
  

  const mainSocket = useWebSocket(
    MAINSOCKET_URL,
    {...MAINSOCKET_CONFIG, onOpen: (openEvent) => wsConnectRequests()})

  function wsConnectRequests() {}

  useEffect(() => {
    setMobile(window.innerWidth < 605)
  }, [])

  function setMobileValue() {
    setMobile(window.innerWidth < 605)
  }

  useEffect(() => {
    window.addEventListener('resize', setMobileValue);
    return () => {
      window.removeEventListener('resize', setMobileValue)
    }
  }, [])

  useEffect(() => {
    const data = mainSocket.lastJsonMessage
    console.log(data)
    if (data != null) {
      if (["nav.push", "play.bot"].includes(data.update)) {
          setGameId(data.game_id)
          setRating(data.rating)
          navigate("/playground")
      }
      else if (["Game joined", "Game left"].includes(data.message)) {
          setRating(data.rating)
          setDollars(data.dollars)
      }
      else if (data.message === "Game created") {
        setRating(data.rating)
        setDollars(data.dollars)
      }
      else if (data.update === "game.state") {
        setGameState(data)
      }
      else if (data.update === "move.push") {
        let gsCopy = {...gameState, type: "move.push"}
        gsCopy.time_remaining = 29.5
        gsCopy.moves_played += 1
        gsCopy.last_played = data.cards
        if (data.user === username) {
          gsCopy.hand = gsCopy.hand.filter(card => !data.cards.includes(card))
          gsCopy.my_turn = false
        }
        else {
          gsCopy.opponent_card_count -= data.cards.length
          gsCopy.my_turn = true
        }
        setGameState(gsCopy)
      }
      else if (data.update === "game.list") {
        setListData(data.ongoing_games)
      }
      else if (data.update === "game.list.update") {
        let ldCopy = listData
        if (data.action === "create_game"){
          data.game.stake = Number(data.game.stake)
          ldCopy[data.game.game_id] = {...data.game, real_money: data.real_money}
        }
        else if (["join_game", "leave_game"].includes(data.action)){
          ldCopy[data.game_id]["players"] = data.players
        }
        else if (data.action === "delete_game"){
          delete ldCopy[data.game_id]
        }
        setListData(ldCopy)
      }
    }
  }, [mainSocket.lastJsonMessage])

  return (
      <div className="App">
        <header>
        <Navbar />
        </header>
        <MainWrap>
          <Routes>
            <Route path="/about" element={
              <Suspense fallback={<div>Loading...</div>}>
                <About />
              </Suspense>
            } />
            <Route path="/rules" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Rules />
              </Suspense>
            } />
            <Route path="/register" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Register />
              </Suspense>
            } />
            <Route path="/login" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Login />
              </Suspense>
            } />
            <Route path="/user" element={
              <Suspense fallback={<div>Loading...</div>}>
                <User />
              </Suspense>
            } />
            <Route path="/" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Home />
              </Suspense>
            } />
            <Route path="/playground" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Playground />
              </Suspense>
            } />
            <Route path="/feedback" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Feedback />
              </Suspense>
            } />
            {/* <Route path="/Trade" element={
              <Suspense fallback={<div>Loading...</div>}>
                <Trade />
              </Suspense>
            } /> */}
            <Route path="/verify-email/:token" element={
              <Suspense fallback={<div>Loading...</div>}>
                <VerifyEmail />
              </Suspense>
            } />
          </Routes>
        </MainWrap>
      </div>
  );
}

export default App;
