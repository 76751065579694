export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL
export const MAINSOCKET_URL = `${WEBSOCKET_URL}/ws/main`
export const MAINSOCKET_CONFIG = {
    share: true,
    retryOnError: true,
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  }
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID