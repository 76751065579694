import './NavStyle.css'
import { Link, useLocation } from "react-router-dom";
import LoginDropdown from './LoginDropdown'
import LogoutDropdown from './LogoutDropdown'
import React from "react"
import { useGlobalStore } from '../../App'
import styled from 'styled-components'
import MoneyToggle from "../MoneyToggle";
import RatingSymbol from '../RatingSymbol';

const logoStyle1 = {
    padding: '0', 
    color:'rgb(145, 0, 0)', 
}

const logoStyle2 = {
    fontSize: '1.2rem', 
    display: 'flex', 
    alignItems: 'end', 
    paddingBottom: '0.3rem'
}


function Navbar() {
    const realMoney = useGlobalStore((state) => state.realMoney)
    const rating = useGlobalStore((state) => state.rating)
    const dollars = useGlobalStore((state) => state.dollars)
    const username = useGlobalStore((state) => state.username)
    const location = useLocation();
    const currentPath = location.pathname;
    const loggedIn = (username && username !== "Guest")

    return (
        <nav className = 'navStyle'>
            <div className = 'big2Logo'>
                <Link style = {logoStyle1} to='/'>BIG2</Link><span style = {logoStyle2}>.uk</span>
            </div>
            {/* {currentPath !== '/playground' && <MoneyToggle />} */}
            <div className="loginDropdown">
                <Link to={(loggedIn) ? '/User' : '/Login'}><span style={{fontFamily: 'Times-Bold', fontSize: '1.2rem'}}>{!realMoney ? <RatingSymbol/> : <span style={{color: 'green'}}>$</span>}{!realMoney ? rating : dollars}</span> &emsp; <span>{(loggedIn) ? username : 'Login'}</span></Link>
                {(loggedIn) ? <LogoutDropdown/> : <LoginDropdown/>}
            </div>
        </nav>
    )
}

export default Navbar