import React, { useState } from 'react';
import { useGlobalStore } from '../App';
import RatingSymbol from './RatingSymbol';


function ToggleSwitch() {
  const setRealMoney = useGlobalStore((state) => state.setRealMoney)
  const realMoney = useGlobalStore((state) => state.realMoney)
  const [isChecked, setIsChecked] = useState(realMoney);

  const handleToggle = () => {
    setRealMoney(!isChecked)
    setIsChecked(!isChecked)
  };

  const containerStyle = {
    position: 'absolute',
    right: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const switchStyle = {
    display: 'flex',
    borderRadius: '20px',
    padding: '2px',
    cursor: 'pointer',
  };

  const labelStyle = {
    color: 'white',
    margin: '0 5px',
  };

  const inputStyle = {
    display: 'none',
  };

  const trackStyle = {
    width: '40px',
    height: '20px',
    backgroundColor: isChecked ? '#2b7b22' : '#D4AF37',
    borderRadius: '20px',
    position: 'relative',
  };

  const thumbStyle = {
    width: '20px',
    height: '20px',
    border: '1px solid black',
    backgroundColor: '#020202',
    borderRadius: '50%',
    position: 'absolute',
    transition: 'transform 0.1s ease-in-out',
    transform: isChecked ? 'translateX(21px)' : 'translateX(-1px)',
  };

  return (
    <div style={containerStyle}>
      <div style={switchStyle} onClick={handleToggle}>
        <label style={labelStyle}><RatingSymbol/></label>
        <input type="checkbox" style={inputStyle} checked={isChecked} readOnly />
        <div style={trackStyle}>
          <div style={thumbStyle}></div>
        </div>
        <label style={{...labelStyle, fontSize: "1.2rem", color: "green"}}>$</label>
      </div>
    </div>
  );
}

export default ToggleSwitch;
