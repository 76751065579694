import React, { useState } from "react";
import { useGlobalStore } from "../App";
import { inputStyle, labelStyle, submitStyle } from "../common_styles/form";
import {SERVER_URL} from "../configuration";
import { useNavigate } from "react-router-dom";


function LoginForm() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const setRating = useGlobalStore((state) => state.setRating)
    const setDollars = useGlobalStore((state) => state.setDollars)
    const setGlobalUsername = useGlobalStore((state) => state.setUsername)
    const navigate = useNavigate()

    function handleUsernameChange(event) {
      setUsername(event.target.value);
    }

    function handlePasswordChange(event) {
      setPassword(event.target.value);
    }
    
    function handleSubmit(event) {
      fetch(
        `${SERVER_URL}/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type':'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        }
      )

      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then(response => {
        if (response.status === 200) {
          setUsername("")
          setPassword("")
          setGlobalUsername(response.body.username)
          setRating(response.body.rating)
          setDollars(response.body.dollars)
          navigate("/")
          window.location.reload()
        }
        else {
          window.alert("Login error, incorrect credentials.")
        }
      })
      event.preventDefault();
    }

    return (
      <form style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} onSubmit={handleSubmit}>
          <label style={labelStyle}>
              Username/Email:
              <input style={inputStyle} type="text" value={username} onChange={handleUsernameChange} />
          </label>
          <label style={labelStyle}>
              Password:
              <input style={inputStyle} type="password" value={password} onChange={handlePasswordChange} />
          </label>
        <input style={submitStyle} type="submit" value="Log In" />
      </form>
      );

  }

export default LoginForm
