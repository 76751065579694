export const boxStyle = {
  backgroundImage: 'linear-gradient(to bottom, #202020, #303030)',
  border: '1px solid black', 
  borderRadius: '3px',
  padding: '0 20px 40px 20px',
  boxShadow: '-5px 8px 20px 0px black',
  display: 'inline-block',
  fontFamily: 'Arial, Helvetica, sans-serif',
}

export const labelStyle = {
  fontFamily: 'sans-serif',
  fontSize: '1rem',
  display: 'block',
  textAlign: 'left',
  color: '#bababa',
  width: '100%',
}

export const inputStyle = {
  padding: '6px 10px',
  margin: '8px 0',
  border: '1px solid black',
  borderRadius: '2px',
  width: '100%',
  fontSize: '0.7em',
}

export const submitStyle = {
  backgroundColor: '#4CAF50',
  fontSize: '1.2rem',
  color: 'white',
  padding: '14px 20px',
  marginTop: '20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
}