import React from "react";
import { Link } from "react-router-dom"
import LoginForm from "../LoginForm";

function LoginDropdown() {

    return (
        <div className="loginDropdownContent" style={{minWidth: '12rem', padding: '0.5rem'}}>
            <LoginForm/>
            <div className='noAccount'>
                <span>Don't have an account?</span>
            </div>
            <div className='registerLink'>
                <Link to='/register'>Sign Up</Link>
            </div>
        </div>
    )
}

export default LoginDropdown